import React from 'react'
import { useSelector } from 'react-redux';

const BreadCrumb = ({
    mainTitle,
    secondTitle,
    thirdTitle,
}) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='breadCrumb' dir={dir}>
            <span>{mainTitle}</span> &gt; <span>{secondTitle}</span> &gt; <span>{thirdTitle}</span>
        </div>
    )
}

export default BreadCrumb
