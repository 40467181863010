import { addPaymentToProject, addStageToProject, createProject, deleteProjects, getAllProject, updateProjects } from "../utils/projects"
import { CREATE_NEW_PROJECT, DEL_PROJECT, GET_PROJECTS, UPDATE_PROJECTS } from "./type/Type"

// Create Project
const create_project = (data) => {
    return {
        type: CREATE_NEW_PROJECT,
        data
    }
}
export const Create_New_project = (data) => {
    return (dispatch) => {

        return createProject(data).then(res => {
            console.log(res)
            if (res.status === 201) {
                dispatch(create_project(res?.data))
            }
            return res
        })
    }
}



// Get All Projects

const get_projects = (data) => {
    return {
        type: GET_PROJECTS,
        data
    }
}

export const Get_All_project = () => {
    return (dispatch) => {

        return getAllProject().then(res => {
            console.log(res)
            if (res.status === 200) {
                dispatch(get_projects(res?.data))
            }
            return res
        })
    }
}

// Delete Projects

const del_projects = (id) => {
    return {
        type: DEL_PROJECT,
        id
    }
}

export const Del_project = (id) => {
    return (dispatch) => {

        return deleteProjects(id).then(res => {
            console.log(res)
            // if (res.status === 200) {
                dispatch(del_projects(id))
            // }
            return res
        })
    }
}

// Update Projects

const update_single_projects = (data) => {
    return {
        type: UPDATE_PROJECTS,
        data
    }
}

export const Update_project = (data,id) => {
    return (dispatch) => {

        return updateProjects(data,id).then(res => {
            console.log(res)
            // if (res.status === 200) {
                dispatch(update_single_projects(res?.data))
            // }
            return res
        })
    }
}

// Update Stage
export const Update_Stage = (data,id) => {
    return (dispatch) => {

        return addStageToProject(data,id).then(res => {
            console.log(res)
            if (res.status === 201) {
                dispatch(update_single_projects(res?.data))
            }
            return res
        })
    }
}
// Update Payment Stage
export const Update_Payment = (data,id) => {
    return (dispatch) => {

        return addPaymentToProject(data,id).then(res => {
            console.log(res)
            if (res.status === 201) {
                dispatch(update_single_projects(res?.data))
            }
            return res
        })
    }
}



