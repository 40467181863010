import React from 'react'
import { useSelector } from 'react-redux';
import detailBlog from "../../assests/detailsBlog.png"
import { FaRegComment } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { HomePage } from '../../translation/trans';

const BlogDetailsCard = ({ blogDetails }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div dir={dir}>
            <h3 style={{ color: "#5480CD", margin: dir === 'rtl' ? '0 180px 0 0' : '0 0 0 180px' }} >{blogDetails?.name}</h3>
            <div>
                <div className="extended-card">
                    <img
                        src={detailBlog}
                        alt="Coding screen"
                        className="card-image"
                    />
                    <div className="card-content">
                        <div className="card-icons">
                            <span className="icon" style={{ color: "#0E3659" }}><MdOutlineRemoveRedEye />
                                {HomePage.view[lang]}</span>
                            <span className="icon" style={{ color: "#0E3659" }}><FaRegComment />
                                {HomePage.comments[lang]}</span>
                        </div>
                        <h2 className="card-title">{blogDetails?.name}</h2>
                        <p className="card-description">
                            {blogDetails?.description}
                            <br />
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogDetailsCard
