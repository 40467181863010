import React from 'react'
import HeaderInfo from '../shared/HeaderInfo'
import partner1 from '../../assests/partner1.png'
import partner2 from '../../assests/partner2.png'
import partner3 from '../../assests/partner3.png'
import { HomePage } from '../../translation/trans'
import { useSelector } from 'react-redux'

const Partners = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div style={{ marginTop: "120px" }} dir={dir}>
            <HeaderInfo title={`${HomePage.partners[lang]}`} info={`${HomePage.partnerOne[lang]}`} />
            <div className='container'>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-4 col-sm-12 col-md-6 col-6'>
                        <div className='partner'>
                            <img src={partner1} alt='parnters' />
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 col-md-6  col-6'>
                        <div className='partner'>
                            <img src={partner2} alt='parnters' />
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 col-md-6  col-6'>
                        <div className='partner'>
                            <img src={partner3} alt='parnters' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partners
