import React from 'react'
import PageContainer from '../../components/layout/PageContainer'
import WorksComponent from '../../components/works/WorksComponent'
import BreadCrumb from "../../components/shared/BreadCrumb"
import { useSelector } from 'react-redux'
import { HomePage } from '../../translation/trans'
const WorksPage = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <PageContainer>
            <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.works[lang]}`} />
            <WorksComponent />
        </PageContainer>
    )
}

export default WorksPage
