import React from 'react'

const HeaderInfo = ({ title, info }) => {
    return (
        <div className='text-center sharedInfo'>
            <h3 className='sharedColor'>{title}</h3>
            <p className='sharedInfoColor'>{info}</p>
        </div>
    )
}

export default HeaderInfo
