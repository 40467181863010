import React from 'react'
import "../../../styles/main.css"
import { IoMdAdd } from "react-icons/io";
import AddServiceModal from '../modals/AddServiceModal';

const AddService = () => {
    return (
        <div>
            <div className="addService">
                <div>
                    <p style={{color:"white"}}>اضف خدمه</p>
                    <div style={{ background: "white", marginTop: "15px",textAlign:"center",borderRadius:"5px" }}>
                        <AddServiceModal/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddService
