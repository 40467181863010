import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceRequest from '../Services/ServiceRequest'
import { useSelector } from 'react-redux'
import { HomePage } from '../../translation/trans'
const CommonQuestions = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <PageContainer>
            <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.contactUs[lang]}`} thirdTitle={`${HomePage.commonQuestions[lang]}`} />
            <div className='mt-5 container' dir={dir}>
                <p className='sharedInfoColor w-50 m-auto'>{HomePage.commonQuestions[lang]}</p>
                <div className='mt-5'>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q1[lang]}</p>
                    </div>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q2[lang]}</p>
                    </div>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q3[lang]}</p>
                    </div>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q4[lang]}</p>
                    </div>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q5[lang]}</p>
                    </div>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q6[lang]}</p>
                    </div>
                    <div className='common text-center sharedHover'>
                        <p>{HomePage.questions.q7[lang]}</p>
                    </div>
                </div>
            </div>
            <ServiceRequest />
        </PageContainer>
    )
}

export default CommonQuestions

