import React from 'react'
import Card from 'react-bootstrap/Card';
import product from '../../assests/card.png'
import { Link } from 'react-router-dom';
import { HomePage } from '../../translation/trans';
import { useSelector } from 'react-redux';
import Blogcard from '../../Pages/Blog/Blogcard';

const Products = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const blogs = useSelector((state) => state.blogRed)

    // Only render if blogs exist and have length
    if (!blogs?.length) return null;

    return (
        <div style={{ marginTop: "120px" }} className='text-center m-auto'>
            <div className='container'>
                <h2 className='mt-5 mb-5'>Our Blogs</h2>
                <div className='row justify-content-between align-items-start'>
                    {blogs.map((blog) => (
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12' key={blog?._id}>
                            <Blogcard blog={blog} />
                        </div>
                    ))}
                </div>
            </div>
            <div className='mt-5'>
                <Link to="/blog" className='mt-5'>{HomePage.watchMore[lang]}</Link>
            </div>
        </div>
    )
}

export default Products
