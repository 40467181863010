import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import SidNav from './sideNav';
import "./dashboard.css";
import { Outlet } from 'react-router-dom';
import { RiMenuFoldFill } from "react-icons/ri";
import { RiMenuUnfoldFill } from "react-icons/ri";
import PageContainer from '../layout/PageContainer';
import BreadCrumb from '../shared/BreadCrumb';
import { useDispatch } from 'react-redux';
import { Get_All_services } from '../../actions/serviceAction';

const Dashboard = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(Get_All_services())
  },[])

  return (

    <PageContainer>
      <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"لوحة التحكم"} />
      <Row>
        {/* First Column */}
        <div className={` custom-col ${isMinimized ? 'minimized' : ''}`}>
          <Button style={{ float: 'left' }} variant="secondary" onClick={handleToggleMinimize}>
            {isMinimized ? <RiMenuFoldFill size={20} /> : <RiMenuUnfoldFill />}
          </Button><br /><br />
          <SidNav minimized={isMinimized} />
        </div>

        {/* Second Column */}
        <Col style={{ width: "100%", flexGrow: "1", overFlow: "hidden" }}>
          <Outlet />
        </Col>
      </Row>
    </PageContainer>

  );
};

export default Dashboard;
