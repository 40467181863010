import React from 'react'
import VisionImage from "../../assests/vision.png"
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
const Vision = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='container' style={{ marginTop: "120px" }} dir={dir}>
            <div className='row justify-content-between align-items-center'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <img style={{ width: "100%" }} src={VisionImage} alt="HomeHeader" />
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <h3 className='sharedColor'>{HomePage.vision[lang]}</h3>
                    <p className='sharedColor info'>
                        {HomePage.visionInfo[lang]}
                    </p>
                    <p className='sharedColor info'>
                        {HomePage.infoThree[lang]}
                    </p>
                    <div className='mt-4'>
                        <button className='btn sharedButtonColor sharedHover'>{HomePage.showMore[lang]}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision
