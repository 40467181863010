import React from 'react'

import "../../../styles/req.css"
const orders = [
    { client: 'محمد علي', request: 'طلب تصميم جديد', date: '22 DEC 7:20 PM' },
    { client: 'محمد علي', request: 'طلب تصميم جديد', date: '22 DEC 7:20 PM' },

];
const Requests = () => {
    return (
        <div>
            <div className="orders-overview">
                <h2 className="title">نظرة عامة على الطلبات</h2>
                <div className="growth">
                    <span className="growth-icon">✔️</span>
                    <span className="growth-text">+30% this month</span>
                </div>
                <ul className="orders-list">
                    {orders.map((order, index) => (
                        <li key={index} className="order-item">
                            <div className="order-details">
                                <span className="client">العميل: {order.client}</span>
                                <span className="request">{order.request}</span>
                                <span className="date">{order.date}</span>
                            </div>
                            {/* <div className="notification-icon">🔔</div> */}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Requests
