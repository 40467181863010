import React from 'react'
import PageContainer from '../../components/layout/PageContainer'
import ServiceComponent from '../../components/Services/ServiceComponent'
import { webSiteReader } from '../../components/shared/Constant'
import DetailsService from '../../components/Services/DetailsService'

const ServicePage = () => {
    return (
        <div>
            <PageContainer>
                <DetailsService />
            </PageContainer>
        </div>
    )
}

export default ServicePage
