import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { MdOutlineModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { Delete_Single_Services } from '../../../actions/serviceAction';
import { ErrorMsg } from '../../shared/Modules/Toast';
const DeleteService = ({ id }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    const handleDeleteService = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Delete_Single_Services(id))
            console.log(res)
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <MdDelete size="25px" color="red" onClick={handleShow} style={{ cursor: "pointer" }} />
            <Modal show={show} onHide={handleClose} centered className="p-5">
                <Modal.Body>
                    <p>هل انت متاكد من حذف هذه الخدمه</p>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: "#4973BC", color: "white" }} disabled={loading}
                        onClick={handleDeleteService}
                    >

                        {loading ? "يتم الحذف..." : "حذف"}
                    </button>
                    <button className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }} onClick={handleClose}>الغاء</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteService
