import React from 'react'
import "../../../styles/main.css"
const HeaderMain = ({ title,
    percentage,
    duration,
    icon }) => {
    return (
        <div className="minheaderdiv">
            <div style={{lineHeight:"15px",padding:"10px"}}>
                <p>{title}</p>
                <p>{percentage}</p>
                <span style={{fontSize:"10px"}}>{duration}</span>
            </div>
            <div>
                <p className="iconHeader">{icon}</p>
            </div>
        </div>
    )
}

export default HeaderMain
