import React from 'react'
import "../../../styles/main.css"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Rate = () => {
    return (
        <div>
            <div className="text-center rate">
                <div>
                    <div>
                        <p style={{ fontSize: "13px" }}>معدل التقييمات</p>
                        <span>كافة الاعمال المعروضه</span>
                    </div>
                    <div className="mt-2 mb-2" style={{ margin: "auto" }}>
                        <CircularProgressbar
                            className='w-50'
                            style={{ fontSize: "13px", color: "white" }}
                            value={70}
                            text={`9.3`}
                            styles={buildStyles({
                                textColor: "#1A3667",
                                pathColor: "#0075FF",
                                trailColor: "#B7D3F4"
                            })}
                        />
                    </div>
                    <div className="analyzeDataOne mt-3" style={{ color: "black" }}>
                        <h4>95%</h4>
                        <span>معدل الاعجاب</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rate
