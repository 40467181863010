import React from 'react'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceRequest from '../Services/ServiceRequest'
import PageContainer from '../layout/PageContainer'
import aboutUs from '../../assests/about2.png'
import { HomePage } from '../../translation/trans'
import { useSelector } from 'react-redux'
const TeamWork = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <PageContainer>
            <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.about[lang]}`} thirdTitle={`${HomePage.teamTitle[lang]}`} />
            <div>
                <img src={aboutUs} alt="policy" className='w-100' />
            </div>
            <div className='container mt-5' dir={dir}>
                <div>
                    <p className='sharedInfoColor'>{HomePage.teamTitle[lang]}</p>
                    <p>{HomePage.teamIntro[lang]}</p>
                    <ol className='orderedList'>
                        <li>{HomePage.teamPoints.point1[lang]}</li>
                        <li>{HomePage.teamPoints.point2[lang]}</li>
                        <li>{HomePage.teamPoints.point3[lang]}</li>
                        <li>{HomePage.teamPoints.point4[lang]}</li>
                        <li>{HomePage.teamPoints.point5[lang]}</li>
                        <li>{HomePage.teamPoints.point6[lang]}</li>
                        <li>{HomePage.teamPoints.point7[lang]}</li>
                    </ol>
                </div>
                <div>
                    <p className='sharedInfoColor'>{HomePage.clientsTitle[lang]}</p>
                    <p>{HomePage.clientsIntro[lang]}</p>
                    <ol className='orderedList'>
                        <li>{HomePage.clientTypes.type1[lang]}</li>
                        <li>{HomePage.clientTypes.type2[lang]}</li>
                    </ol>
                </div>
            </div>
            <ServiceRequest />
        </PageContainer>
    )
}

export default TeamWork
