import React from 'react'
import "../../../styles/main.css"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const AnalyzeData = () => {
    const percentage = 70;
    return (
        <div>
            <div className="analyzeData">
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <p>تحليل بيانات الاحاله</p>
                        <div className="analyzeDataOne" style={{}}>
                            <p>تم دخول 145 شخص</p>
                        </div>
                        <div className="analyzeDataOne" style={{}}>
                            <p>تم دخول 145 شخص</p>
                        </div>
                    </div>
                    <div className="" style={{ marginLeft: "0", textAlign: "left" }}>
                        <CircularProgressbar
                            className='w-50'
                            style={{fontSize:"13px",color:"white"}}
                            value={percentage}
                            text={`9.3`}
                            styles={buildStyles({
                                textColor: "#1A3667",
                                pathColor: "#0075FF",
                                trailColor: "#B7D3F4"
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyzeData
