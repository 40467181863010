
import axios from "axios";
import { urlBase } from "./API";

// Create Service
export const createProject = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/projects`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}` // إضافة التوكن في الهيدر
        }
    })

}


// Get Projects

export const getAllProject = async () => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/projects`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


// Del Projects

export const deleteProjects = async (id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/projects/${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}



// Update Projects

export const updateProjects = async (data, id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/projects/${id}`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


// Add Stage To Project
export const addStageToProject = async (data, id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/projects/${id}/stages`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


// Add Payment To Project
export const addPaymentToProject = async (data, id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/projects/${id}/payment-stages`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


// Get My Projects   projects/me
export const GetMyProjects = async () => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/projects/me`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}