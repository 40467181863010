import React from 'react'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceComponent from './ServiceComponent'
import { webSiteReader } from '../shared/Constant'
import PageContainer from '../layout/PageContainer'

const WebsiteService = () => {
    return (
        <div>
            <PageContainer>
                <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"تطوير تطبيقات الهاتف  "} />
                <ServiceComponent reader={webSiteReader} readKey={true} />
            </PageContainer>
        </div>
    )
}

export default WebsiteService
