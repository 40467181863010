import React from 'react';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';


const ProjectOwner = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div style={{ marginTop: "120px" }}>
            <div className='container'>
                <div className='row justify-content-between align-items-center m-auto'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mb-2'>
                        <div className='cardOwner text-center '>
                            <h4 className='sharedColor' style={{ width: "80%" }}>
                                {HomePage.projectOwnerData[lang]}
                            </h4>
                            <button className='btn login mt-4 sharedHover'>
                                {HomePage.contactUs[lang]}
                            </button>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mb-2'>
                        <div className='cardOwner text-center'>
                            <h4 className='sharedColor w-75'>
                                {HomePage.projectData[lang]}
                            </h4>
                            <button className='btn login mt-4 sharedHover'>
                                {HomePage.contactUs[lang]}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectOwner;
