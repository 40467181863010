import React from 'react'
import Chart from '../FooterMain/Chart'
import AddProject from './AddProject'
import ShowProjects from './ShowProjects'
import Requests from './Requests'

const MiddleDash = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 ol-12 mb-3">
                        <Chart />
                    </div>
                    <div className='col-lg-3 col-md-12 col-sm-12 ol-12 mb-3'>
                        <Requests/>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 ol-12 mb-3">
                        <div>
                            <AddProject />
                        </div>
                        <div className="mt-3">
                            <ShowProjects />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MiddleDash
