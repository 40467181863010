import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceComponent from './ServiceComponent'
import { maintainceSites } from '../shared/Constant'
const MaintainceService = () => {
    return (
        <div>
            <div>
                <PageContainer>
                    <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"أدارة المواقع وصيانتها"} />
                    <ServiceComponent reader={maintainceSites} readKey={false} />
                </PageContainer>
            </div>
        </div>
    )
}

export default MaintainceService
