import React from 'react'
import StartService from '../Modals/StartService'
import { HomePage } from '../../translation/trans';
import { useSelector } from 'react-redux';

const ServiceRequest = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div style={{ marginTop: "120px" }} className='serviceRequest' dri={dir}>
            <div className='d-flex justify-content-between align-items-center'>
                <p>{HomePage.requestService[lang]}</p>
                <StartService />
            </div>
            <div className='d-flex justify-content-between align-items-center mt-5'>
                <p className='w-50'>{HomePage.scheduleInfo[lang]}</p>
                <button className='btn sharedBtn sharedHover'>
                    {HomePage.bookAppointment[lang]}
                </button>
            </div>
        </div>
    )
}

export default ServiceRequest
