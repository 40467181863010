import React from 'react'
import { useState } from 'react';
import { IoMdAdd } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import 'react-phone-input-2/lib/style.css';
import { ErrorMsg, success } from '../../shared/Modules/Toast';
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { Create_New_service, Update_Single_Services } from '../../../actions/serviceAction';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
const UpdateService = ({ service }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [price, setPrice] = useState(service?.price);
    const [name, setName] = useState(service?.name);
    const [desc, setDesc] = useState(service?.description);
    const [status, setStatus] = useState(service?.status);
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(service?.coverImage);
    const [icon, setIcon] = useState(service?.icon);
    const dispatch = useDispatch();

    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/uploads/single`,
            data: formData,
        })
    }

    const handleNewService = async () => {
        setLoading(true)
        try {
            const payload = {
                price: price,
                name: name,
                description: desc,
                status: status
            }

            if (image instanceof File) {
                const response = await uploadingFileLocally(image);
                payload.coverImage = response?.data?.filename;
            }

            if (icon instanceof File) {
                const response2 = await uploadingFileLocally(icon);
                payload.icon = response2?.data?.filename;
            }

            const res = await dispatch(Update_Single_Services(payload, service?._id));

            if (res?.status === 200) {
                success("تم تحديث الخدمه بنجاح")
                handleClose();
            }
        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                const errorMessage = e.response.data.message;
                if (Array.isArray(errorMessage)) {

                    errorMessage.forEach(msg => {
                        ErrorMsg(msg);
                    });
                } else {

                    ErrorMsg(errorMessage);
                }
            }
        } finally {
            setLoading(false)
        }
    }


    return (
        <div>
            {/* <button className='btn sharedBtn sharedHover' >
                ابدا الان
            </button> */}
            <MdOutlineModeEdit color="white" size="25px" onClick={handleShow} style={{ cursor: "pointer" }} />
            <Modal show={show} onHide={handleClose} centered >
                <div style={{ backgroundColor: "#4973BC", color: "white", textAlign: "center" }}>
                    <p style={{ textAlign: "center", padding: "10px" }}>تحديث خدمه</p>
                </div>
                <div className="modalServicesBody">
                    <div>
                        <div>
                            <label>اسم الخدمه</label>
                            <input type="text" name="name" id="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div>
                            <label>صورة الخدمه</label>
                            <input type="file" name="name" id="name" className="form-control"
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    const url = window.URL.createObjectURL(event.target.files[0]);
                                }}
                            />
                        </div>
                        <div>
                            <label>ايقونة الخدمه</label>
                            <input type="file" name="name" id="name" className="form-control"
                                onChange={(event) => {
                                    setIcon(event.target.files[0]);
                                    const url = window.URL.createObjectURL(event.target.files[0]);
                                }}
                            />
                        </div>
                        <div>
                            <label>السعر</label>
                            <input type="number" name="price" id="price" className="form-control"
                                value={price} onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>


                        <div>
                            <label>حالة الخدمه</label>
                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option hidden>Status</option>
                                <option value={"active"}>Active</option>
                                <option value={"inactive"}>In Active</option>
                            </select>
                        </div>
                        <div>
                            <label>وصف الخدمه</label>
                            <textarea className="form-control w-100" value={desc} onChange={(e) => setDesc(e.target.value)} style={{ resize: "none" }}></textarea>
                        </div>

                    </div>
                    <div className="mt-4" style={{ textAlign: "left" }}>
                        <button onClick={handleClose} className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>
                            الغاء
                        </button>
                        <button onClick={handleNewService} disabled={loading} className='btn' style={{ backgroundColor: "#4973BC", color: "white" }}>
                            {loading ? "تحميل" : "حفظ"}
                        </button>
                    </div>
                </div>



            </Modal>
        </div>
    )
}

export default UpdateService
