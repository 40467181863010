import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { Create_New_blog } from '../../../actions/blog';
import { success } from "../../shared/Modules/Toast"
const AddBlogs = ({blog}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [desc, setDesc] = useState(blog?.description || "") 
    const [name, setName] = useState(blog?.name || "")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            } catch (error) {
                console.log("Invalid user data in localStorage");
                localStorage.removeItem("user");
            }
        }
    }, []);

    const handleAddBlog = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Create_New_blog({
                name: name,
                description: desc,
                user: user?._id

            }))
            if (res?.status === 201) {
                success("تم اضافة المدونه بنجاح")
            }
        } catch (e) {

        } finally {
            setLoading(false);
            setDesc("");
            setName("");
            handleClose();
        }
    }
    return (
        <div>
            <button className='btn login mt-4' onClick={handleShow}>Add Blogs</button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div style={{ backgroundColor: "#4973BC", color: "white", textAlign: "center" }}>
                        <p style={{ textAlign: "center", padding: "10px" }}>اضافة مدونه</p>
                    </div>
                    <div className="modalServicesBody">
                        <div>
                            <div>
                                <label>اسم المدونه</label>
                                <input type="text" name="name" id="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>



                            <div>
                                <label>وصف المدونه</label>
                                <textarea className="form-control w-100" value={desc} onChange={(e) => setDesc(e.target.value)} style={{ resize: "none" }}></textarea>
                            </div>

                        </div>
                        <div className="mt-4" style={{ textAlign: "left" }}>
                            <button onClick={handleClose} className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>
                                الغاء
                            </button>
                            <button onClick={handleAddBlog} disabled={loading} className='btn' style={{ backgroundColor: "#4973BC", color: "white" }}>
                                {loading ? "تحميل" : "اضافه"}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddBlogs
