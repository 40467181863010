import React from 'react'
import { useSelector } from 'react-redux';
import ServiceRequest from '../../components/Services/ServiceRequest';
import Blogcard from './Blogcard';
import { HomePage } from '../../translation/trans';
import PageContainer from '../../components/layout/PageContainer';
import BreadCrumb from '../../components/shared/BreadCrumb';
import { useParams } from 'react-router-dom';
import BlogDetailsCard from './BlogDetailsCard';
import "./styles/blog.css"
const BlogDetails = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const blogs = useSelector((state) => state.blogRed)

    const params = useParams();

    const blogDetails = blogs?.find((blog) => blog._id === params.id);

    return (
        <div>
            <PageContainer>
                <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.blogs[lang]}`}  thirdTitle={`${blogDetails?.name}`}/>
                <div className='px-5'>
                    <div className='row justify-content-between align-items-start py-5' dir={dir}>
                        <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                            <h4>{HomePage.modernBlog[lang]}</h4>
                            {
                                blogs?.map((blog) => {
                                    return (
                                        <div className='mt-4 mb-3'>
                                            <Blogcard blog={blog} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='col-lg-9 col-md-6 col-sm-12 col-12'>
                            <BlogDetailsCard blogDetails={blogDetails}/>
                        </div>
                    </div>
                </div>

                <ServiceRequest />
            </PageContainer>
        </div>
    )
}

export default BlogDetails
