import React from 'react'
import AddProjectModal from './AddProjectModal'

const AddProject = () => {
    return (
        <div>
            <div className="addService">
                <div>
                    <p style={{ color: "white" }}>اضف مشروع</p>
                    <div style={{ background: "white", marginTop: "15px", textAlign: "center", borderRadius: "5px" }}>
                        <AddProjectModal/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProject
