import React from 'react'
import "../../../styles/main.css"
const Ospash = () => {
    return (
        <div className="ospashDiv">
           <div>
           <p style={{color:"#A0AEC0",fontSize:"13px"}}>مرحبا بك</p>
            <h4>د/علي عفيفي</h4>
            <span style={{color:"#A0AEC0",fontSize:"13px"}}>اطلع علي احدث نتائج Ospash</span>
           </div>
        </div>
    )
}

export default Ospash
