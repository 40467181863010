import React from 'react'
import ShowServicesReq from './modules/ShowServicesReq'

const Requests = () => {
    return (
        <div>
            <div>
                <ShowServicesReq />
            </div>
        </div>
    )
}

export default Requests
