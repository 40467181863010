
import axios from "axios";
import { urlBase } from "./API";

// Create Blog
export const createBlog = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/blogs`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// Get All Blogs
export const getBlogs = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/blogs`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// Delete Single Blogs
export const delBlogs = (id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/blogs/${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}



// Update Single Blogs
export const updateBlogs = (data,id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/blogs/${id}`,
        data:data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}