import React, { useState } from 'react'
import PageContainer from '../../layout/PageContainer'
import BreadCrumb from '../BreadCrumb'
import "../../../styles/register.css"
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMsg, success } from './Toast'
import { loginUser } from '../../../utils/API'
import { HomePage } from '../../../translation/trans'
const Login = () => {

    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // const handleLogin = async () => {
    //     setLoading(true)
    //     try {
    //         const resLogin = await loginUser({
    //             email: email,
    //             password: pass,
    //         })
    //         console.log(resLogin)
    //         if (resLogin?.status === 200) {
    //             success(`تم التسجيل بنجاح`)
    //             localStorage.setItem('token', resLogin.data.access_token)
    //             localStorage.setItem('user', JSON.stringify(resLogin.data.user));
    //             if (resLogin?.data?.user?.role === "admin") {
    //                 // navigate(`/dashboard/${resLogin?.data?.user?._id}`)
    //                 setTimeout(() => {
    //                     navigate(`/dashboard/${resLogin?.data?.user?._id}`)
    //                 }, 2000)
    //             } else {
    //                 if (resLogin?.data?.user?.role === "client") {

    //                     setTimeout(() => {
    //                         navigate(`/dashboard/${resLogin?.data?.user?._id}/client`)
    //                     }, 2000)
    //                 }
    //             }
    //         }

    //     } catch (e) {
    //         console.log(e)
    //         if (e?.response?.data) {
    //             ErrorMsg(e?.response?.data?.message)
    //         } else {
    //             ErrorMsg("هناك مشكله")
    //         }

    //     } finally {
    //         setLoading(false)
    //     }
    // }
    const handleLogin = async () => {
        setLoading(true)
        try {
            const resLogin = await loginUser({
                email: email,
                password: pass,
            })
            if (resLogin?.status === 200) {
                success(`تم التسجيل بنجاح`)
                localStorage.setItem('token', resLogin.data.access_token)
                localStorage.setItem('user', JSON.stringify(resLogin.data.user));

                // Trigger storage event manually
                window.dispatchEvent(new Event('storage'))

                if (resLogin?.data?.user?.role === "admin") {
                    setTimeout(() => {
                        navigate(`/dashboard/${resLogin?.data?.user?._id}`)
                    }, 2000)
                } else if (resLogin?.data?.user?.role === "client") {
                    setTimeout(() => {
                        navigate(`/dashboard/${resLogin?.data?.user?._id}/client`)
                    }, 2000)
                }
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            } else {
                ErrorMsg("هناك مشكله")
            }
        } finally {
            setLoading(false)
        }
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <PageContainer>
                <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.reg[lang]}`} />
                {/* Componenet Register */}
                <div className='registerDiv w-100'>
                    <div className="row w-100">
                        <div className="col-lg-7 col-md-6 col-sm-12">

                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12" dir={dir}>
                            <div style={{ color: "white" }} className="register-component">
                                <div className='text-center'>
                                    <h4>{HomePage.welcome[lang]} <span style={{ color: "#4D78C3" }}>Osbash</span></h4>

                                </div>
                                <div>

                                    <div className="mt-5">
                                        <label>{HomePage.email[lang]}</label>
                                        <input type="email" name="email" id="email" className=' inputs'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <label>{HomePage.password[lang]}</label>
                                        <input type="password" name="email" id="email" className=' inputs'
                                            value={pass}
                                            onChange={(e) => setPass(e.target.value)}
                                        />
                                    </div>
                                    <div className="text-center mt-5">
                                        <button className="btn w-75" style={{ backgroundColor: "#4B76C0", color: "white" }}
                                            onClick={handleLogin}
                                            disabled={loading}
                                        >


                                            {loading ? HomePage.loading[lang] : HomePage.login[lang]}
                                        </button>
                                        <div>
                                            {HomePage.noAccount[lang]} <Link to="/register">{HomePage.signUpNow[lang]}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Login
