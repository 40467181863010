
import axios from "axios";
import { urlBase } from "./API";

// Create Service
export const createService = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/Service`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}` // إضافة التوكن في الهيدر
        }
    })

}

// Get All Services
export const getServices = () => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/Service`,
        headers: {
            'Authorization': `Bearer ${token}` // إضافة التوكن في الهيدر
        }
    })
}

// Delete Service
export const deleteService = (id) => {
    const token = localStorage.getItem('token');

    return axios({
        method: "DELETE",
        url: `${urlBase}/api/Service/${id}`,
        headers: {
            'Authorization': `Bearer ${token}` // إضافة التوكن في الهيدر
        }
    })
}


// Update Service
export const serviceUpdate = (data, id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/Service/${id}`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}` // إضافة التوكن في الهيدر
        }
    })
}


// Get Single Service

export const getSingleServiceData = (id)=>{
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/Service/${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}