import React from 'react'
import ServiceComponent from './ServiceComponent'
import { phoneReader } from '../shared/Constant'
import BreadCrumb from '../shared/BreadCrumb'
import PageContainer from '../layout/PageContainer'

const PhoneService = () => {
    return (
        <div>
            <PageContainer>
                <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"تطوير تطبيقات الهاتف  "} />
                <ServiceComponent reader={phoneReader} readKey={false} />
            </PageContainer>
        </div>
    )
}

export default PhoneService
