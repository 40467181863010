import React from 'react'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceRequest from '../Services/ServiceRequest'
import PageContainer from '../layout/PageContainer'
import aboutUs from '../../assests/about3.png'
const AboutCompany = () => {
    return (
        <PageContainer>
            <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"من نحن"} thirdTitle={"معلومات الشركة وتاريخها"} />
            <div>
                <img src={aboutUs} alt="policy" className='w-100' style={{ height: "30vh", objectFit: "fill" }} />
            </div>
            <div className='container mt-5 sharedColor' style={{textAlign:"left"}} dir='ltr'>
                <p className='sharedColor'>
                    Welcome to Osbash Agency, where innovation meets expertise. We are a dynamic team dedicated to pushing the boundaries of programming and digital marketing. Our leader and founder, Dr. Ali Afifi, is not just a senior MERN stack developer; he's a visionary with a remarkable journey in both technology and academia.
                </p>

                <p>About Dr. Ali Afifi:</p>
                <p>Dr. Ali Afifi embarked on his programming journey in 2020, initially learning from Udacity's FWD program. He began as a Front-End developer and swiftly transitioned into a proficient MERN stack developer. Dr. Afifi's work has extended far and wide, collaborating with clients from across the globe, including Saudi Arabia, Morocco, Kiewit, India, USA, France, Syria, Egypt, Jordan, Lebanon, and the United Arab Emirates.</p>

                <p>Some of his notable clients include:</p>
                <p>Alfuttiam Company (Emirates)</p>
                <p>Makzany Company (The first self-storage company in Saudi Arabia)</p>
                <p>Almasri Group Company (Kiewit)</p>
                <p>
                    But Dr. Afifi's expertise isn't confined to the tech world. He's also a physicist researcher, having earned his degree from Al-Azhar University in 2014. After a 3-year tenure as a Reserve Officer in the Egyptian Army, he furthered his career as a physics teaching assistant for 2 years at the Faculty of Engineering – Modern Academy. In 2023, he completed his Master's degree in solid-state physics and boasts international publications in the field.
                </p>


                <p>
                In 2023, Dr. Afifi founded Osbash Agency, where he leads and oversees the Programming department. Our Marketing department is under the capable management of Mr. Al-Hassan Ahmed, a seasoned marketer with 5 years of experience across various trading sectors.
                </p>

                <p>Our Services:</p>

                <p>At Osbash Agency, we offer a comprehensive suite of services to meet your business needs:</p>
                <p>Custom Management Systems (ERP, CRM)</p>
                <p>Mobile Applications</p>
                <p>Websites</p>
                <p>Web Platforms</p>
                <p>Marketing Campaigns (Google, Social Media)</p>
                <p>UI/UX Design</p>
                <p>Graphic Design</p>
            </div>
            <ServiceRequest />
        </PageContainer>
    )
}

export default AboutCompany
