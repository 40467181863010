import React from 'react'
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
import imageBlog from "../../assests/blog.png"
import { Link } from 'react-router-dom';
const Blogcard = ({ blog }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className="card" dir={dir}>
                <img
                    src={imageBlog}
                    alt="Circuit board"
                    className="card-image"
                />
                <div className="card-content">
                    <div className="card-date">25 NOV 2024</div>
                    <h2 className="card-title">{blog?.name}</h2>
                    <p className="card-description">
                        {blog?.description?.substring(0, 20)}...
                    </p>
                    <Link to={`/blog/${blog?._id}`} className="card-link">{HomePage.watchMore[lang]}</Link>
                </div>
            </div>
        </div>
    )
}

export default Blogcard
