// import React, { useEffect } from 'react'
// import ServiceRequest from "../Services/ServiceRequest"
// import { useDispatch, useSelector } from 'react-redux'
// import { Get_All_project } from '../../actions/projectsAction'
// import { FaEye, FaLink } from 'react-icons/fa'
// import { urlBase } from '../../utils/API'
// import "../../styles/works.css"
// import { HomePage } from '../../translation/trans'

// const WorksComponent = () => {
//   const dispatch = useDispatch()
//   const projectsData = useSelector((state) => state.projectRed)

//   const completedProjects = projectsData?.filter(project => project.status === "completed");

//   const { lang, dir } = useSelector(({ language }) => {
//     return { lang: language.lang, dir: language.dir };
//   });

//   return (
//     <div className='container'>
//       <div>
//         <h4 className='sharedColor text-center mt-5'>
//           {HomePage.allWorks[lang]}
//         </h4>
//       </div>
//       <div className='row g-4 mt-5'>
//         {completedProjects?.map((project) => (
//           <div className='col-lg-6 col-md-6 col-sm-12' key={project._id}>
//             <div className='project-card'
//               style={{
//                 backgroundImage: `url(${urlBase}/static-uploads/${project.coverImage})`
//               }}>
//               <div className='project-overlay'>
//                 <div className='project-icons'>
//                   <div className='icon-wrapper'>
//                     <FaEye size={24} />
//                   </div>
//                   <div className='icon-wrapper'>
//                     <FaLink size={24} />
//                   </div>
//                 </div>
//                 <h4 className='project-title'>{project.name}</h4>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       <ServiceRequest />
//     </div>
//   )
// }

// export default WorksComponent

import React, { useEffect } from 'react'
import ServiceRequest from "../Services/ServiceRequest"
import { useDispatch, useSelector } from 'react-redux'
import { Get_All_project } from '../../actions/projectsAction'
import { FaEye, FaLink } from 'react-icons/fa'
import { urlBase } from '../../utils/API'
import "../../styles/works.css"
import { HomePage } from '../../translation/trans'

const WorksComponent = () => {
  const dispatch = useDispatch()
  const projectsData = useSelector((state) => state.projectRed)

  const completedProjects = projectsData?.filter(project => project.status === "completed");

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <div className='container'>
      <div>
        <h4 className='sharedColor text-center mt-5'>
          {HomePage.allWorks[lang]}
        </h4>
      </div>
      {completedProjects?.length > 0 ? (
        <div className='row g-4 mt-5'>
          {completedProjects.map((project) => (
            <div className='col-lg-6 col-md-6 col-sm-12' key={project._id}>
              <div className='project-card'
                style={{
                  backgroundImage: `url(${urlBase}/static-uploads/${project.coverImage})`
                }}>
                <div className='project-overlay'>
                  <div className='project-icons'>
                    <div className='icon-wrapper'>
                      <FaEye size={24} />
                    </div>
                    <div className='icon-wrapper'>
                      <FaLink size={24} />
                    </div>
                  </div>
                  <h4 className='project-title'>{project.name}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center mt-5">
          <h3>{HomePage.noWorks[lang]}</h3>
        </div>
      )}
      <ServiceRequest />
    </div>
  )
}

export default WorksComponent
