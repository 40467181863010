import React from 'react'
import PageContainer from '../../components/layout/PageContainer'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { HomePage } from '../../translation/trans'
import { useSelector } from 'react-redux'
import Blogcard from './Blogcard'
import "./styles/blog.css"
import ServiceRequest from '../../components/Services/ServiceRequest'
const BlogMain = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const blogs = useSelector((state) => state.blogRed)
    return (
        <div>
            <PageContainer>
                <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.blogs[lang]}`} />
                <div className='px-5'>
                    <div className='row justify-content-between align-items-start py-5' dir={dir}>
                        <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                            <h4>{HomePage.modernBlog[lang]}</h4>
                            {
                                blogs?.map((blog) => {
                                    return (
                                        <div className='mt-4 mb-3'>
                                            <Blogcard blog={blog} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='col-lg-9 col-md-6 col-sm-12 col-12'>
                            <div className='row justify-content-between align-items-start'>
                                {
                                    blogs?.map((blog) => {
                                        return (
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <Blogcard blog={blog} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <ServiceRequest />
            </PageContainer>
        </div>
    )
}

export default BlogMain
