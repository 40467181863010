import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
const StartService = () => {
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState("");
    const [whatsApp, setWhatsApp] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePhone = (valuePhone) => {
        setPhone(valuePhone)
    }
    const handleWhatsApp = (valuePhone) => {
        setWhatsApp(valuePhone)
    }
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <button className='btn sharedBtn sharedHover' onClick={handleShow}>
                {HomePage.statNow[lang]}
            </button>
            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <div style={{ backgroundColor: "#4973BC", color: "white", textAlign: "center" }}>
                    <p style={{ textAlign: "center", padding: "10px" }}>{HomePage.serviceFormTitle[lang]}</p>
                </div>
                <div className="modalServicesBody">
                    <div>
                        <div>
                            <label>{HomePage.name[lang]}</label>
                            <input type="text" name="name" id="name" className="form-control" />
                        </div>
                        <div>
                            <label>{HomePage.email[lang]}</label>
                            <input type="email" name="email" id="email" className="form-control" />
                        </div>
                        <div>
                            <label>{HomePage.whatsapp[lang]}</label>
                            <PhoneInput
                                country={'eg'}
                                className="inputs"
                                style={{ width: "100%" }}
                                value={phone}
                                onChange={(phone) => handleWhatsApp(phone)}
                            />
                        </div>
                        <div>
                            <label>{HomePage.phone[lang]}</label>
                            <PhoneInput
                                country={'eg'}
                                className="inputs"
                                style={{ width: "100%" }}
                                value={phone}
                                onChange={(phone) => handlePhone(phone)}
                            />
                        </div>
                        <div>
                            <label>{HomePage.services[lang]}</label>
                            <select className="form-control">
                                <option hidden>{HomePage.selectService[lang]}</option>

                            </select>
                        </div>

                    </div>
                    <div className="mt-4" style={{ textAlign: "left" }}>
                        <button onClick={handleClose} className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>
                            {HomePage.cancel[lang]}
                        </button>
                        <button onClick={handleClose} className='btn' style={{ backgroundColor: "#4973BC", color: "white" }}>
                            {HomePage.save[lang]}
                        </button>
                    </div>
                </div>



            </Modal>
        </div>
    )
}

export default StartService
