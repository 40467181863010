import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceComponent from './ServiceComponent'
import { graphicReader } from '../shared/Constant'
const GraphicServices = () => {
    return (
        <div>
            <div>
                <PageContainer>
                    <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"تصميم الجرافيك والموشن"} />
                    <ServiceComponent reader={graphicReader} readKey={false} />
                </PageContainer>
            </div>
        </div>
    )
}

export default GraphicServices
