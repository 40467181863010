import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import "../../../styles/main.css"
const data = [
    { name: 'Jan', uv: 200 },
    { name: 'Feb', uv: 300 },
    { name: 'Mar', uv: 250 },
    { name: 'Apr', uv: 100 },
    { name: 'May', uv: 150 },
    { name: 'Jun', uv: 280 },
    { name: 'Jul', uv: 400 },
    { name: 'Aug', uv: 350 },
    { name: 'Sep', uv: 270 },
    { name: 'Oct', uv: 200 },
    { name: 'Nov', uv: 150 },
    { name: 'Dec', uv: 250 }
];
const Chart = () => {
    return (
        //         <ResponsiveContainer width="100%" height={400} className="chart">
        //             <AreaChart
        //                 data={data}
        //                 margin={{
        //                     top: 10, right: 30, left: 0, bottom: 0,
        //                 }}
        //             >
        //                 <CartesianGrid strokeDasharray="3 3" />
        //                 <XAxis dataKey="name" />
        //                 <YAxis />
        //                 <Tooltip />
        //                 <Area type="monotone" dataKey="uv" stroke="#0075FF" fill="background: linear-gradient(180deg, #FFFFFF 0%, rgba(0, 117, 255, 0) 100%);
        // " />
        //             </AreaChart>
        //         </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={400} className="chart">
            <AreaChart
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#0075FF" stopOpacity={0.7} />
                        <stop offset="100%" stopColor="#0075FF" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#0075FF"
                    fill="url(#colorUv)" // Reference the gradient
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default Chart
