import React from 'react'
import Chart from './Chart'
import AddService from './AddService'
import ShowService from './ShowService'

const FooterMain = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12 ol-12 mb-3">
                    <Chart />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 ol-12 mb-3">
                    <div>
                        <AddService />
                    </div>
                    <div className="mt-3">
                        <ShowService/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterMain
