import React from 'react'
// import HomeHeaderImage from "../../assests/homeheader.png"
import HomeHeaderImage from "../../assests/logo.gif"
import { FaPlay } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
const HomeHeader = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='container' dir={dir}>
            <div className='row justify-content-between align-items-center'>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <p className='sharedColor info'>{HomePage.infoOne[lang]}</p>
                    <h3 className='sharedColor'>
                        {HomePage.infoTwo[lang]}
                    </h3>
                    <h3 className='sharedColor'>
                        {HomePage.infoThree[lang]}
                    </h3>

                    <div className='mt-4'>
                        <button className='btn sharedButtonColor sharedHover'>{HomePage.start[lang]}</button>
                        <button className='btn sharedButtonColor playBtn mx-3 fs-5 sharedHover'><FaPlay /></button>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <img style={{ width: "100%" }} src={HomeHeaderImage} alt="HomeHeader" />
                </div>
            </div>
        </div>
    )
}

export default HomeHeader
