import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceComponent from './ServiceComponent'
import { uxReader } from '../shared/Constant'
const UxServices = () => {
    return (
        <div>
            <div>
                <PageContainer>
                    <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"تصميم تجربة المستخدم و واجهة المستخدم"} />
                    <ServiceComponent reader={uxReader} readKey={false} />
                </PageContainer>
            </div>
        </div>
    )
}

export default UxServices
