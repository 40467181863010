
import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { Del_project } from '../../../actions/projectsAction';
import { Modal } from 'react-bootstrap';
const DeleteProject = ({ proId }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const dispatch = useDispatch();

    const handleDelProject = async () => {
        try {
            const res = await dispatch(Del_project(proId))
        } catch (e) {
            console.log(e);
            handleClose();
        }
    }

    return (
        <div>
            <MdDelete onClick={handleShow} size={"25px"} color="red" />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <p>Are you sure you want to delete this project?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                    <button className='btn btn-danger' onClick={handleDelProject}>Delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteProject
