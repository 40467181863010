import React from 'react';
import HeaderDefault from '../shared/HeaderDefault';
import FooterWidgets from '../shared/FooterWidgets';

const initHeaders = (
    <>
        <HeaderDefault />
    </>
);
const initFooters = (
    <>
        <FooterWidgets />
    </>
);

const PageContainer = ({
    header = initHeaders,
    footer = initFooters,
    children,
    title,
}) => {
    let titleView;


    return (
        <>
            <title>{titleView}</title>
            {header}
            {children}
            {footer}
        </>
    );
};

export default PageContainer;
