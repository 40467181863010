import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { intializeLogIn } from "./actions/signin-up";
import "./components/general.css"
import Sign from "./components/signInUp/sign";
import Dashboard from "./components/dashboard/dashboard";
import Profile from "./components/dashboard/profile";
import Affilate from './components/affilation/Affilate';
import Team from './components/userTeam/team';
import HomePage from "./Pages/Home/HomePage";
import PhoneService from "./components/Services/PhoneService";
import WebsiteService from "./components/Services/WebsiteService";
import MarketingService from "./components/Services/MarketingService";
import HoistingService from "./components/Services/HoistingService";
import SeoServices from "./components/Services/SeoServices";
import UxServices from "./components/Services/UxServices";
import MaintainceService from "./components/Services/MaintainceService";
import GraphicServices from "./components/Services/GraphicServices";
import ReportServices from "./components/Services/ReportServices";
import Policy from "./components/About/Policy";
import TeamWork from "./components/About/Team";
import AboutCompany from "./components/About/AboutCompany";
import ClientSupport from "./components/contact/ClientSupport";
import CommonQuestions from "./components/contact/CommonQuestions";
import ContactUs from "./components/contact/ContactUs";
import WorksPage from "./Pages/WorksFolder/WorksPage";
import Register from "./components/shared/Modules/Register";
import Login from "./components/shared/Modules/Login";
import { ToastContainer } from "react-toastify";
import MainComponent from "./components/Admin/MainComponent";
import Requests from "./components/Admin/Requests/Requests";
import ServicePage from "./Pages/Services/ServicePage";
import Projects from "./components/Admin/MiddlDash/Projects";
import { Get_All_project } from "./actions/projectsAction";
import Blog from "./components/Admin/Blogs/Blog";
import BlogMain from "./Pages/Blog/BlogMain";
import { get_All_Blogs } from "./actions/blog";
import BlogDetails from "./Pages/Blog/BlogDetails";
import DashboardClient from "./Pages/Client/Dashboard/DashboardClient";
import HeaderClient from "./Pages/Client/Dashboard/HeaderClient";
import Messages from "./Pages/Client/Dashboard/Messages";
import Notification from "./Pages/Client/Dashboard/Notification";
import Timeline from "./Pages/Client/Dashboard/ServiceDashboard";
function App() {


  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  // useEffect(() => {
  //   const body = document.querySelector('body');
  //   body.style.direction = 'rtl';
  //   body.style.fontFamily = 'Noto Kufi Arabic';

  //   if (localStorage.getItem("user")) {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     setUser(user);
  //   }
  //   dispatch(Get_All_project())

  //   // dispatch(intializeLogIn());
  // }, [])



  useEffect(() => {
    const handleAuthChange = () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);
        } catch (error) {
          console.log("Invalid user data in localStorage");
          localStorage.removeItem("user");
        }
      }
    };

    // Listen for storage events
    window.addEventListener('storage', handleAuthChange);
    // Initial check
    handleAuthChange();

    return () => {
      window.removeEventListener('storage', handleAuthChange);
    };
  }, []);


  useEffect(() => {
    const body = document.querySelector('body');
    body.style.direction = 'rtl';
    body.style.fontFamily = 'Noto Kufi Arabic';
    // dispatch(Get_All_project());
    dispatch(get_All_Blogs());
  }, [])





  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        {/* <Route path="*" element={<Sign />} /> */}

        <Route path="*" element={<HomePage />} />

        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/services/:id" element={<ServicePage />} />
        <Route path="/services-website" element={<WebsiteService />} />
        <Route path="/services-phone" element={<PhoneService />} />
        <Route path="/services-marketing" element={<MarketingService />} />
        <Route path="/services-design" element={<UxServices />} />
        <Route path="/services-seo" element={<SeoServices />} />
        <Route path="/services-hoisting" element={<HoistingService />} />
        <Route path="/services-maintaince" element={<MaintainceService />} />
        <Route path="/services-reports" element={<ReportServices />} />
        <Route path="/services-graphic" element={<GraphicServices />} />
        <Route path="/blog" element={<BlogMain />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        {/* About US */}
        <Route path="/policy" element={<Policy />} />
        <Route path="/team" element={<TeamWork />} />

        {/* Contact US */}
        <Route path="/client-support" element={<ClientSupport />} />
        <Route path="/common-question" element={<CommonQuestions />} />
        <Route path="/about-company" element={<AboutCompany />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* Works */}
        <Route path="/works" element={<WorksPage />} />



        {user && user?.role === "client" && (
          <>
            {/* insert user routes here */}
            <Route path="/dashboard/:user/client" element={<DashboardClient />}>
              <Route path="" element={<HeaderClient />} />
              <Route path="messages" element={<Messages />} />
              <Route path="notifications" element={<Notification />} />
              <Route path="service" element={<Timeline />} />
            </Route>
          </>
        )}




        {/* Affilation route */}
        <Route path="/affilate/:id" element={<Affilate />} />
        {/* Admin Routes */}


        {/* user routes : repeat this part if more than 1 type of users  */}
        {user && user?.role === "admin" && (
          <>
            {/* insert user routes here */}
            <Route path="/dashboard/:user" element={<Dashboard />}>

              <Route path="" element={<MainComponent />} />
              <Route path="services-requests" element={
                <Requests />
              } />
              <Route path="projects" element={
                <Projects />
              } />
              {/* user routes */}
              {user.role === 'user' && <>
                <Route path='team' element={<Team />} />
              </>}
              <Route path="blogs-system" element={<Blog />} />
            </Route>
          </>
        )}

      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}
const mapStateToProps = ({ loggedUser }) => {
  const user = { ...loggedUser };
  return { user };
};
export default App;
