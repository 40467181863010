import React from 'react'
import BreadCrumb from '../shared/BreadCrumb'
import PageContainer from '../layout/PageContainer'
import ServiceRequest from '../Services/ServiceRequest'
import aboutUs from '../../assests/aboutUs.png'
import { useSelector } from 'react-redux'
import { HomePage } from '../../translation/trans'
const Policy = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <PageContainer>
            <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.about[lang]}`} thirdTitle={`${HomePage.policyTitle[lang]}`} />
            <div dir={dir}>
                <div>
                    <img src={aboutUs} alt="policy" className='w-100' />
                </div>
                <div className='container mt-5'>
                    <p className='sharedInfoColor'>{HomePage.policyTitle[lang]}</p>
                    <p>{HomePage.policyIntro[lang]}</p>
                    <ol className='orderedList'>
                        <li>{HomePage.policyPoints.point1[lang]}</li>
                        <li>{HomePage.policyPoints.point2[lang]}</li>
                        <li>{HomePage.policyPoints.point3[lang]}</li>
                        <li>{HomePage.policyPoints.point4[lang]}</li>
                        <li>{HomePage.policyPoints.point5[lang]}</li>
                        <li>{HomePage.policyPoints.point6[lang]}</li>
                        <li>{HomePage.policyPoints.point7[lang]}</li>
                    </ol>
                </div>
            </div>
            <ServiceRequest />
        </PageContainer>
    )
}

export default Policy
