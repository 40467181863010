import React from 'react'
import Ospash from './Ospash'
import AnalyzeData from './AnalyzeData'
import Rate from './Rate'

const Analyze = () => {
    return (
        <div className="container">
            <div className='row mt-4'>
                <div className="col-lg-5 col-md-6 col-sm-12 col-12 mb-3">
                    <Ospash />
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 col-12 mb-3">
                    <AnalyzeData />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 col-12 mb-3">
                    <Rate />
                </div>
            </div>
        </div>
    )
}

export default Analyze
