import React from 'react'
import "../../styles/static.css"
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import PageContainer from '../layout/PageContainer';
import BreadCrumb from '../shared/BreadCrumb';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
const ContactUs = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <PageContainer>
            <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.home[lang]}`} thirdTitle={`${HomePage.contactWithUs[lang]}`} />

            <p className='sharedInfoColor w-75 ' dir={dir} style={{ margin: "50px auto" }}>{HomePage.haveQue[lang]}</p>
            <div className='rootContact container'>
                <div className='contactUs mt-3 mb-3' dir={dir}>
                    <div className='contentContactUs py-3'>
                        <div className=' w-75 m-auto'>
                            <div className='row justify-content-between align-items-center'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label>
                                        {HomePage.email[lang]}
                                    </label>
                                    <br />
                                    <input type="email" className='input' name="email" id="email" />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label>{HomePage.confirmEmail[lang]}</label>
                                    <br />
                                    <input type="email" className='input' name="email" id="email" />
                                </div>
                            </div>

                            <div>
                                <label>{HomePage.contactNum[lang]}</label>
                                <br />
                                <input type="text" className='input contactInfo' name="email" id="email" />
                            </div>

                            <div>
                                <label>{HomePage.message[lang]}</label>
                                <br />
                                <textarea style={{ resize: "none" }} cols={"120"} rows={"12"}>

                                </textarea>
                            </div>
                        </div>


                        <div style={{ textAlign: "left", width: "75%", marginTop: "10px" }}>
                            <button className='btn saveContact me-3'>{HomePage.save[lang]}</button>
                            <button className='btn cancelContact me-3'>{HomePage.cancel[lang]}</button>
                        </div>


                        <div className='text-center mt-5'>
                            <p style={{ color: "#4184BF" }}>{HomePage.or[lang]}</p>
                            <p style={{ color: "#4184BF" }}>{HomePage.contactViaSocial[lang]}</p>
                            <div>
                                <FaFacebookF size={"25px"} className='me-2' color='#4184BF' />
                                <FaTwitter size={"25px"} className='me-2' color='#4184BF' />
                                <RiInstagramFill size={"25px"} className='me-2' color='#4184BF' />
                                <FaLinkedin size={"25px"} className='me-2' color='#4184BF' />
                                <FaYoutube size={"25px"} className='me-2' color='#4184BF' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </PageContainer>
    )
}

export default ContactUs
