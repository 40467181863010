import React, { useState } from 'react'
import PageContainer from '../../layout/PageContainer'
import BreadCrumb from '../BreadCrumb'
import "../../../styles/register.css"
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Register_New_User } from '../../../actions/signin-up'
import { ErrorMsg, success } from './Toast'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { HomePage } from "../../../translation/trans"
import { useSelector } from 'react-redux'
const Register = () => {
    // States For Register User
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [jop, setJop] = useState("");
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const handlePhone = (valuePhone) => {
        setPhone(valuePhone)
    }

    const navigate = useNavigate()

    const handleRegister = async () => {
        setLoading(true)
        try {
            const resRegister = await dispatch(Register_New_User({
                name: name,
                email: email,
                password: pass,
                phone: `+${phone}`,
                country: country,
                address: address,
                job: jop
            }))
            console.log(resRegister)
            if (resRegister?.status === 201) {
                success(`تم التسجيل بنجاح`)
                localStorage.setItem('token', resRegister.data.access_token)
                localStorage.setItem('user', JSON.stringify(resRegister.data.user));
                navigate("/")
            }

        } catch (e) {
            console.log(e)
            ErrorMsg("هناك مشكله")
        } finally {
            setLoading(false)
        }
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <>
            <PageContainer>
                <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.reg[lang]}`} />

                {/* Componenet Register */}
                <div className='registerDiv w-100'>
                    <div className="row w-100">
                        <div className="col-lg-7 col-md-6 col-sm-12">

                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12" dir={dir}>
                            <div style={{ color: "white" }} className="register-component">
                                <div className='text-center'>
                                    <h4>{HomePage.welcome[lang]} <span style={{ color: "#4D78C3" }}>Osbash</span></h4>

                                </div>
                                <div>
                                    <div className="mt-1">
                                        <label>{HomePage.email[lang]}</label>
                                        <input type="email" name="email" id="email" className=' inputs' value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>{HomePage.username[lang]}</label>
                                        <input type="text" name="name" id="name" className=' inputs' value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>{HomePage.password[lang]}</label>
                                        <input type="password" name="pass" id="pass" className=' inputs' value={pass}
                                            onChange={(e) => setPass(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>{HomePage.phone[lang]}</label>
                                        <PhoneInput
                                            country={'eg'}
                                            className="inputs"
                                            style={{ width: "100%" }}
                                            value={phone}
                                            onChange={(phone) => handlePhone(phone)}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>{HomePage.country[lang]}</label>
                                        <input type="text" name="country" id="country" className=' inputs' value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>{HomePage.address[lang]}</label>
                                        <input type="text" name="address" id="address" className=' inputs' value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <label>{HomePage.job[lang]}</label>
                                        <input type="text" name="jop" id="jop" className=' inputs' value={jop}
                                            onChange={(e) => setJop(e.target.value)}
                                        />
                                    </div>
                                    <div className="text-center mt-3">
                                        <button className="btn w-75" style={{ backgroundColor: "#4B76C0", color: "white" }}
                                            onClick={handleRegister}
                                            disabled={loading}
                                        >
                                            {loading ? HomePage.loading[lang] : HomePage.register[lang]}
                                        </button>
                                        <div className="mt-1">
                                            {HomePage.haveAccount[lang]} <Link to="/login">
                                                {HomePage.login[lang]}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>

        </>
    )
}

export default Register
