import { CREATE_NEW_SERVICE, GET_ALL_SERVICE, DELETE_SINGLE_SERVICE, UPDATE_SERVICE } from "../actions/type/Type";


export const serviceReducer = (state = [], action) => {
    switch (action.type) {
        case CREATE_NEW_SERVICE:
            return state = [...state, action.data];
        case GET_ALL_SERVICE:
            return state = action.data
        case UPDATE_SERVICE:
            return state = state.map(s => {
                if (s._id === action.data?._id) {
                    return action.data
                }
                return s
            })
        case DELETE_SINGLE_SERVICE:
            return state = state.filter(s => s._id !== action.id)
        default:
            return state;
    }
};




