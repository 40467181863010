import React, { useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap';
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { del_Single_Blog } from '../../../actions/blog';
const DeleteBlog = ({ blog }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        try {
            setLoading(true)
            const res = await dispatch(del_Single_Blog(blog?._id));
            console.log(res)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <MdDeleteOutline onClick={handleShow} cursor={"pointer"} />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <p>هل انت متاكد من حذف هذ المدونه</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary'>Cancel</button>
                    <button className='btn btn-danger' onClick={handleDelete} disabled={loading}>
                        {loading ? "Deleting..." : "Delete"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteBlog
