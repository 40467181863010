import React from 'react'
import HeaderInfo from '../shared/HeaderInfo'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "../../styles/service.css"
import { urlBase } from '../../utils/API';
import { HomePage } from '../../translation/trans';

const Services = () => {
    const services = useSelector((state) => state.serviceReducer);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <HeaderInfo title={`${HomePage.servicesSec[lang]}`} info={`${HomePage.servicesInfo[lang]}`} />
            <div className='container mt-5'>
                <div className='row'>
                    {Array.isArray(services) && services.map((service) => (
                        <div className='col-lg-4 col-md-4 col-sm-12 col-6 mb-5' key={service?._id}>
                            <div className="service-hover-card">
                                <div className="service-content">
                                    <Link to={`/services/${service._id}`} className='sharedColor' style={{ textDecoration: "none" }}>
                                        {service?.name}
                                    </Link>
                                </div>
                                <div className="service-icon">
                                    <img src={`${urlBase}/static-uploads/${service.icon}`} alt={service.name} />
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default Services


