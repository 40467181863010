// import React, { useEffect } from 'react'
// import AddBlogs from './AddBlogs'
// import { useDispatch, useSelector } from 'react-redux';
// import { get_All_Blogs } from '../../../actions/blog';
// import { Table } from 'react-bootstrap';
// import { FiEdit } from "react-icons/fi";
// import { MdDeleteOutline } from "react-icons/md";
// import DeleteBlog from './DeleteBlog';
// import EditBlog from './EditBlog';

// const Blog = () => {
//     const dispatch = useDispatch();

//     useEffect(() => {
//         const handleGetBlogs = async () => {
//             try {
//                 const res = await dispatch(get_All_Blogs())
//             } catch (e) {
//                 console.log(e)
//             } finally {

//             }
//         }
//         handleGetBlogs();
//     }, [])

//     const blogs = useSelector((state) => state.blogRed);

//     console.log("blllooogggss", blogs)

//     return (
//         <div>
//             <AddBlogs />

//             <div className='container'>
//                 <Table responsive className='mt-5'>
//                     <thead>
//                         <tr>
//                             <th>الاسم</th>
//                             <th>الوصف</th>
//                             <th>Edit</th>
//                             <th>Delete</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {
//                             blogs?.map((blog) => {
//                                 return (
//                                     <tr>
//                                         <td>{blog.name}</td>
//                                         <td>{blog?.description?.substring(0, 20)}...</td>
//                                         <td>
//                                             <EditBlog blog={blog} />
//                                         </td>
//                                         <td>
//                                             <DeleteBlog blog={blog} />
//                                         </td>
//                                     </tr>
//                                 )
//                             })
//                         }
//                     </tbody>
//                 </Table>
//             </div>
//         </div>
//     )
// }

// export default Blog

import React, { useEffect, useState } from 'react'
import AddBlogs from './AddBlogs'
import { useDispatch, useSelector } from 'react-redux';
import { get_All_Blogs } from '../../../actions/blog';
import { Table, Modal } from 'react-bootstrap';
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import DeleteBlog from './DeleteBlog';
import EditBlog from './EditBlog';

const Blog = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');

    const handleShowModal = (description) => {
        setSelectedDescription(description);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const handleGetBlogs = async () => {
            try {
                const res = await dispatch(get_All_Blogs())
            } catch (e) {
                console.log(e)
            }
        }
        handleGetBlogs();
    }, [])

    const blogs = useSelector((state) => state.blogRed);

    return (
        <div>
            <AddBlogs />

            <div className='container'>
                <Table responsive className='mt-5'>
                    <thead>
                        <tr>
                            <th>الاسم</th>
                            <th>الوصف</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            blogs?.map((blog) => {
                                return (
                                    <tr key={blog._id}>
                                        <td>{blog.name}</td>
                                        <td>
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                onClick={() => handleShowModal(blog.description)}
                                            >
                                                {blog?.description?.substring(0, 20)}...
                                            </span>
                                        </td>
                                        <td>
                                            <EditBlog blog={blog} />
                                        </td>
                                        <td>
                                            <DeleteBlog blog={blog} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Full Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedDescription}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Blog
