import { CREATE_NEW_BLOG, DEL_SINGLE_BLOG, GET_ALL_BLOGS, UPDATE_SINGLE_BLOG, } from "../actions/type/Type";



export const blogRed = (state = [], action) => {
    switch (action.type) {
        case CREATE_NEW_BLOG:
            return state = [...state, action.data];
        case GET_ALL_BLOGS:
            return state = action.data
        case UPDATE_SINGLE_BLOG:
            return state = state.map(s => {
                if (s._id === action?.data?._id) {
                    return action.data
                }
                return s
            })
        case DEL_SINGLE_BLOG:
            return state = state.filter(s => s._id !== action.id)
        default:
            return state;
    }
};




