import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceComponent from './ServiceComponent'
import { reportsReader } from '../shared/Constant'
const ReportServices = () => {
    return (
        <div>
            <div>
                <PageContainer>
                    <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"التحليلات والتقارير"} />
                    <ServiceComponent reader={reportsReader} readKey={false} />
                </PageContainer>
            </div>
        </div>
    )
}

export default ReportServices
