import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceRequest from '../Services/ServiceRequest'
import { useSelector } from 'react-redux'
import { HomePage } from '../../translation/trans'

const ClientSupport = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <PageContainer>
            <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.contactWithUs[lang]}`} thirdTitle={`${HomePage.technicalSupportTitle[lang]}`} />
            <div className='mt-5 container' dir={dir}>
                <p className='sharedInfoColor'>{HomePage.technicalSupportTitle[lang]}</p>
                <p>{HomePage.technicalSupportIntro[lang]}</p>
                <div>
                    <ol className='orderedList'>
                        <li>{HomePage.supportPoints.point1[lang]}</li>
                        <li>{HomePage.supportPoints.point2[lang]}</li>
                        <li>{HomePage.supportPoints.point3[lang]}</li>
                        <li>{HomePage.supportPoints.point4[lang]}</li>
                        <li>{HomePage.supportPoints.point5[lang]}</li>
                        <li>{HomePage.supportPoints.point6[lang]}</li>
                    </ol>
                </div>
            </div>
            <ServiceRequest />
        </PageContainer>
    )
}

export default ClientSupport
