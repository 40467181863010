
import React from "react";
import "../../styles/shared.css"
import LogoRhaya from '../../assests/logo.png'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HomePage } from "../../translation/trans";
const FooterWidgets = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div className="footer mt-5" style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
            <div className="row jusrify-content-between align-items-center" dir={dir}>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div>
                        <img src={LogoRhaya} alt="Logo Rhaya" />
                        <p style={{ color: "white" }}>
                            {HomePage.footerCompanyDesc[lang]}
                        </p>
                    </div>
                </div>
                <div className="col-lg-9 col-md-6 col-sm-12">
                    <div
                        className="row justify-content-between align-items-center col-12"
                        style={{ flexWrap: "wrap", width: "100%" }}
                    >
                        <div className="col-lg-3 ">
                            <div>
                                <aside className="widget widget_footer" >

                                    <div className="widget_content">

                                        <ul className="ps-list--social">
                                            <li className="widget-title">{HomePage.quickLinks[lang]}</li>
                                            <li>
                                                <Link className="facebook" to={"/"}>
                                                    {HomePage.home[lang]}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="facebook" to={""}>
                                                    {HomePage.services[lang]}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="facebook" to={"/works"}>
                                                    {HomePage.works[lang]}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="facebook" to={"/blog"}>
                                                    {HomePage.blog[lang]}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div>
                                <aside className="widget widget_footer">
                                    <ul className="ps-list--social">
                                        <li className="widget-title">{HomePage.contactUs[lang]}</li>
                                        <li>
                                            <Link className="facebook" to={"/contact-us"}>
                                                {HomePage.contactUs[lang]}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="facebook" to={"/policy"}>
                                                {HomePage.privacyPolicy[lang]}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="facebook" to={"/common-question"}>
                                                {HomePage.faq[lang]}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="facebook" to={"/client-support"}>
                                                {HomePage.technicalSupport[lang]}
                                            </Link>
                                        </li>

                                    </ul>
                                </aside>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div>
                                <aside className="widget widget_footer">

                                    <ul className="ps-list--social">
                                        <li className="widget-title">{HomePage.aboutUs[lang]}</li>
                                        <li>
                                            <Link className="facebook" to="/about-company">
                                                <span>{HomePage.aboutOsbash[lang]}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="facebook" to="*">
                                                <span>{HomePage.visionValues[lang]}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="facebook" to="/team">
                                                <span>{HomePage.teamPartners[lang]}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr style={{ color: "white" }}></hr>

            <div className="d-flex justify-content-between align-items-center" style={{ color: "white" }}>
                <p>
                    <Link to="/policy" style={{ color: "white" }}>All Rights Reserved | Terms and Conditions | Privacy Policy</Link>
                </p>
                <p>©2024.Osbash.e. All rights reserved</p>
            </div>
        </div>
    );
};

export default FooterWidgets;
