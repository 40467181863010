// import React from 'react'
// import "../../../../styles/main.css"
// import { useSelector } from 'react-redux';
// import { MdDelete } from "react-icons/md";
// import { MdOutlineModeEdit } from "react-icons/md";
// import DeleteService from '../../modals/DeleteService';
// import UpdateService from '../../modals/UpdateService';
// import { urlBase } from '../../../../utils/API';

// const ShowServicesReq = () => {

//     const serviceData = useSelector((state) => state.serviceReducer);

//     console.log(serviceData)

//     return (
//         <div>
//             <table className="tableReq table-table-responsive">
//                 <thead className="p-5">
//                     <th>ايقونة الخدمة</th>
//                     <th>ًصورة الخدمة</th>
//                     <th>اسم الخدمة</th>
//                     <th>السعر</th>
//                     <th>حالة العمل</th>
//                     <th>الصوره</th>
//                     <th>تعديل</th>
//                     <th>حذف</th>
//                 </thead>
//                 <tbody className="p-5">
//                     {
//                         serviceData?.map((service) => {
//                             return (
//                                 <tr key={service?._id} className="mt-3 p-3">
//                                     <td>
//                                         <img src={`${urlBase}/static/${service?.icon}`} alt="iamgedata" />
//                                     </td>
//                                     <td>
//                                         <img src={`${urlBase}/static/${service?.coverImage}`} alt="iamgedata" />
//                                     </td>
//                                     <td>{service?.name}</td>
//                                     <td>{service?.price}</td>
//                                     <td>{service?.status}</td>
//                                     <td>Active</td>
//                                     <td>
//                                         <UpdateService service={service} />
//                                         {/* <MdOutlineModeEdit color="white" size="25px" /> */}
//                                     </td>
//                                     <td>
//                                         <DeleteService id={service?._id} />
//                                     </td>
//                                 </tr>
//                             )
//                         })
//                     }

//                 </tbody>
//             </table>
//         </div>
//     )
// }

// export default ShowServicesReq

import React from 'react'
import "../../../../styles/main.css"
import { useSelector } from 'react-redux';
import { MdDelete } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import DeleteService from '../../modals/DeleteService';
import UpdateService from '../../modals/UpdateService';
import { urlBase } from '../../../../utils/API';

const ShowServicesReq = () => {
    const serviceData = useSelector((state) => state.serviceReducer);

    return (
        <div>
            <table className="tableReq table-table-responsive">
                <thead className="p-5">
                    <th>ايقونة الخدمة</th>
                    <th>صورة الخدمة</th>
                    <th>اسم الخدمة</th>
                    <th>السعر</th>
                    <th>حالة العمل</th>
                    <th>الصوره</th>
                    <th>تعديل</th>
                    <th>حذف</th>
                </thead>
                <tbody className="p-5">
                    {serviceData?.map((service) => (
                        <tr key={service?._id} className="mt-3 p-3">
                            <td className="table-image-cell">
                                <img 
                                    src={`${urlBase}/static-uploads/${service?.icon}`} 
                                    alt="service icon"
                                    className="table-image"
                                />
                            </td>
                            <td className="table-image-cell">
                                <img 
                                    src={`${urlBase}/static-uploads/${service?.coverImage}`} 
                                    alt="service cover"
                                    className="table-image"
                                />
                            </td>
                            <td>{service?.name}</td>
                            <td>{service?.price}</td>
                            <td>{service?.status}</td>
                            <td>Active</td>
                            <td>
                                <UpdateService service={service} />
                            </td>
                            <td>
                                <DeleteService id={service?._id} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ShowServicesReq
