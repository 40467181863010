import { combineReducers } from "redux";
import { serviceReducer } from "./serviceReducer";
import { projectRed } from "./projectRed";
import { language } from "./language";
import { blogRed } from "./blogRed";
// import { loggedUser } from "./signin-up";
export default combineReducers({
  // loggedUser,
  serviceReducer,
  projectRed,
  language,
  blogRed,
});
