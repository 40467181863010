import React from 'react'
import profileImage from '../../../assests/message.png'
import "./style/dash.css"
const Messages = () => {
    return (
        <div>
            <button className='btn' style={{ color: "white", background: "#5480CD", borderRadius: "20px" }}>ارسال رساله جديده</button>

            <div>
                <div className="chat-message">
                    <div className="chat-time">3:28 AM</div>
                    <div className="chat-content">
                        <img src={profileImage} alt={"data"} className="chat-avatar" />
                        <div className="chat-text">
                            <div className="chat-name">منه محمد</div>
                            <div className="chat-message-text">مرحبا أود أن أرسل رسالة لك</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages
