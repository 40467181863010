import React from 'react';
import { FaStar, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import HeaderInfo from '../shared/HeaderInfo';
import Person from '../../assests/person.png';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}

            style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
            onClick={onClick}
        >
            <FaArrowRight size={30} color="#3E66AD" />
        </div>
    );
}

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "10px", zIndex: 2 }}
            onClick={onClick}
        >
            <FaArrowLeft size={30} color="#3E66AD" />
        </div>
    );
}

const Comment = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div style={{ margin: "120px auto", textAlign: "center", padding: "20px", width: "90%" }}>
            <HeaderInfo title={`${HomePage.Comments[lang]}`} info={`${HomePage.commentInfo[lang]}`} />
            <div className='container'>
                <Slider {...settings}>
                    <div className='d-flex justify-content-between align-items-center comment'>
                        <div>
                            <img src={Person} alt="person" />
                        </div>
                        <div>
                            <div className="quote-icon" style={{ textAlign: "right", fontSize: "50px" }}>“</div>
                            <h4 className='sharedColor'>MR. Abdelrahman alghatani</h4>
                            <p className='sharedInfoColor'>AGA institute</p>
                            <p>
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                            </p>
                            <p>{HomePage.commentOne[lang]}</p>
                            <div className="quote-icon" style={{ textAlign: "left", fontSize: "50px" }}>“</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center comment'>
                        <div>
                            <img src={Person} alt="person" />
                        </div>
                        <div>
                            <div className="quote-icon" style={{ textAlign: "right", fontSize: "50px" }}>“</div>
                            <h4 className='sharedColor'>MR. Abdelrahman alghatani</h4>
                            <p className='sharedInfoColor'>AGA institute</p>
                            <p>
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                            </p>
                            <p>{HomePage.commentOne[lang]}</p>
                            <div className="quote-icon" style={{ textAlign: "left", fontSize: "50px" }}>“</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center comment'>
                        <div>
                            <img src={Person} alt="person" />
                        </div>
                        <div>
                            <div className="quote-icon" style={{ textAlign: "right", fontSize: "50px" }}>“</div>
                            <h4 className='sharedColor'>MR. Abdelrahman alghatani</h4>
                            <p className='sharedInfoColor'>AGA institute</p>
                            <p>
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                                <FaStar color='#FC9155' style={{ marginRight: "12px" }} />
                            </p>
                            <p>{HomePage.commentOne[lang]}</p>
                            <div className="quote-icon" style={{ textAlign: "left", fontSize: "50px" }}>“</div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Comment;
