import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Get_All_project } from "../../../actions/projectsAction"
import { MdDelete } from "react-icons/md";
import DeleteProject from './DeleteProject';
import ModalUpdate from './ModalUpdate';
import { urlBase } from '../../../utils/API';
import ModalAddStage from './ModalAddStage';
import ModalAddPaymentStage from './ModalAddPaymentStage';
import { MdModeEditOutline } from "react-icons/md";
const Projects = () => {
    const [selectedDescription, setSelectedDescription] = useState(null);
    const dispatch = useDispatch();

    const handleGetProjects = async () => {
        try {
            // const res = await dispatch(Get_All_project());
            // console.log(res)
        } catch (e) {
            console.log(e)
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return dateString.split('T')[0];
    }

    useEffect(() => {
        handleGetProjects();
    }, [])

    const projectsData = useSelector((state) => state.projectRed);

    const handleDescriptionClick = (description) => {
        setSelectedDescription(selectedDescription === description ? null : description);
    }


    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const handleShowUpdateModal = (project) => {
        setSelectedProject(project);
        setShowUpdateModal(true);
    };

    return (
        <div>
            <table className="tableReq table-table-responsive">
                <thead className="p-5">
                    <th>صورة المشروع</th>
                    <th>اسم المشروع</th>
                    <th>وصف المشروع</th>
                    <th>تاريخ البدايه</th>
                    <th>تاريخ النهايه</th>
                    <th>حالة المشروع</th>
                    <th>Add Stage</th>
                    <th>Add Payment Stage</th>
                    <th>تعديل</th>
                    <th>حذف</th>
                </thead>
                <tbody className="p-5">
                    {
                        projectsData?.map((pro) => {
                            return (
                                <tr key={pro?._id} className="mt-3 p-3">
                                    <td className="table-image-cell">
                                        <img
                                            src={`${urlBase}/static-uploads/${pro?.coverImage}`}
                                            alt="service icon"
                                            className="table-image"
                                        />
                                    </td>
                                    {/* <td>
                                        <img src={`${urlBase}/static/${pro.coverImage}`} alt="iamge" />
                                    </td> */}
                                    <td>{pro?.name}</td>
                                    <td style={{ position: 'relative', cursor: 'pointer' }} onClick={() => handleDescriptionClick(pro?.description)}>
                                        {pro?.description?.substring(0, 10)}...
                                        {selectedDescription === pro?.description && (
                                            <div style={{
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                padding: '10px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                zIndex: 1000,
                                                minWidth: '200px',
                                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                                color: "black"
                                            }}>
                                                {pro?.description}
                                            </div>
                                        )}
                                    </td>
                                    <td>{formatDate(pro?.startDate)}</td>
                                    <td>{formatDate(pro?.endDate)}</td>
                                    <td>{pro?.status}</td>
                                    <td>
                                        <ModalAddStage pro={pro} />
                                    </td>
                                    <td>
                                        <ModalAddPaymentStage pro={pro} />
                                    </td>
                                    <td>
                                        <MdModeEditOutline
                                            onClick={() => handleShowUpdateModal(pro)}
                                            size={"25px"}
                                            color="white"
                                            cursor={"pointer"}
                                        />
                                        {/* <ModalUpdate pro={pro} /> */}
                                    </td>
                                    <td>
                                        <DeleteProject proId={pro?._id} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <ModalUpdate
                show={showUpdateModal}
                setShow={setShowUpdateModal}
                pro={selectedProject}
            />
        </div>
    )
}

export default Projects
