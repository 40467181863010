import React from 'react'
import HeaderMain from './modules/HeaderMain'
import { FaShoppingCart } from "react-icons/fa";
import Analyze from './modules/Analyze';
import FooterMain from './FooterMain/FooterMain';
import MiddleDash from './MiddlDash/middleDash';

const MainComponent = () => {
    return (
        <div>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                        <HeaderMain title={"صفقات"} percentage={"173,000"} duration={" last month"} icon={<FaShoppingCart />} />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                        <HeaderMain title={"صفقات"} percentage={"173,000"} duration={" last month"} icon={<FaShoppingCart />} />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                        <HeaderMain title={"صفقات"} percentage={"173,000"} duration={" last month"} icon={<FaShoppingCart />} />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                        <HeaderMain title={"صفقات"} percentage={"173,000"} duration={" last month"} icon={<FaShoppingCart />} />
                    </div>
                </div>
            </div>

            <div className="mt-5">
                <Analyze />
            </div>
            <div className="mt-5">
                <MiddleDash />
            </div>

            <div className="" style={{ marginTop: "120px" }}>
                <FooterMain />
            </div>

        </div>
    )
}

export default MainComponent
