import React from 'react'
import PageContainer from '../layout/PageContainer'
import BreadCrumb from '../shared/BreadCrumb'
import ServiceComponent from './ServiceComponent'
import { socialReader } from '../shared/Constant'
const SeoServices = () => {
    return (
        <div>
            <div>
                <PageContainer>
                    <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={"تحسين وسائل التواصل الإجتماعي ومحركات البحث"} />
                    <ServiceComponent reader={socialReader} readKey={false} />
                </PageContainer>
            </div>
        </div>
    )
}

export default SeoServices
