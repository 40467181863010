import { createBlog, delBlogs, getBlogs, updateBlogs } from "../utils/blogs"
import { CREATE_NEW_BLOG, DEL_SINGLE_BLOG, GET_ALL_BLOGS, UPDATE_SINGLE_BLOG } from "./type/Type"


// Create Blog
const create_blog = (data) => {
    return {
        type: CREATE_NEW_BLOG,
        data
    }
}
// Get Blogs
const get_blogs = (data) => {
    return {
        type: GET_ALL_BLOGS,
        data
    }
}
// Get Blogs
const del_blogs = (id) => {
    return {
        type: DEL_SINGLE_BLOG,
        id
    }
}



export const Create_New_blog = (data) => {
    return (dispatch) => {

        return createBlog(data).then(res => {
            console.log(res)
            if (res.status === 201) {
                dispatch(create_blog(res?.data))
            }
            return res
        })
    }
}


export const get_All_Blogs = () => {
    return (dispatch) => {

        return getBlogs().then(res => {
            console.log(res)
            if (res.status === 200) {
                dispatch(get_blogs(res?.data))
            }
            return res
        })
    }
}


export const del_Single_Blog = (id) => {
    return (dispatch) => {

        return delBlogs(id).then(res => {
            console.log(res)
            dispatch(del_blogs(id))
            return res
        })
    }
}

const update_blogs = (data) => {
    return {
        type: UPDATE_SINGLE_BLOG,
        data
    }
}

export const update_Single_Blog = (data,id) => {
    return (dispatch) => {

        return updateBlogs(data,id).then(res => {
            console.log(res)
            if(res.status === 200){
                dispatch(update_blogs(res?.data , id))
            }
            return res
        })
    }
}