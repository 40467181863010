import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { PiContactlessPaymentFill } from "react-icons/pi";
import { useDispatch } from 'react-redux';
import { ErrorMsg, success } from '../../shared/Modules/Toast';
import { Update_Payment } from '../../../actions/projectsAction';

const ModalAddPaymentStage = ({ pro }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);

    const [nameStage, setNameStage] = useState("");
    const [descStage, setDescStage] = useState("");
    const [date, setDate] = useState("");
    const [status, setStatus] = useState("");
    const [price, setPrice] = useState("");
    const [discountPrice, setDiscountPrice] = useState("");
    const dispatch = useDispatch();
    const handleAddPayment = async () => {
        try {
            setLoading(true)
            const res = await dispatch(Update_Payment({
                name: nameStage,
                description: descStage,
                date: date,
                price: Number(price),
                discount: Number(discountPrice),
                status: status,
            }, pro?._id))
            if (res?.status === 201) {
                success(`Stage updated of Project ${pro?.name} successfully`);
                handleClose();
            }
        } catch (e) {
            console.log(e);
            if (e?.response?.data?.message) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.forEach(msg => {
                        ErrorMsg(msg);
                    });
                } else {
                    ErrorMsg(e.response.data.message);
                }
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <PiContactlessPaymentFill onClick={handleShow} className='mx-2' style={{ fontSize: "25px", cursor: "pointer" }} />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="stages-section mt-4">
                        <h5>Payment Stage</h5>

                        <div className="stage-item border p-3 mb-3">
                            <div className="form-group">
                                <label>اسم مرحلة الدفع</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={nameStage}
                                    onChange={(e) => setNameStage(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>الوصف</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={descStage}
                                    onChange={(e) => setDescStage(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>السعر</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>سعر الخصم</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={discountPrice}
                                    onChange={(e) => setDiscountPrice(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>التاريخ</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>الحالة</label>
                                <select
                                    className="form-control"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option hidden>Choose Status</option>
                                    <option value="paid">paid</option>
                                    <option value="unpaid">unpaid</option>

                                </select>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>الغاء</button>
                    <button disabled={loading} className='btn' onClick={handleAddPayment} style={{ backgroundColor: "#4973BC", color: "white" }}>
                        {loading ? "تحميل" : "اضافه"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAddPaymentStage
