import React from 'react'
import ServiceRequest from './ServiceRequest'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { urlBase } from '../../utils/API'

const ServiceComponent = ({ reader, imageCover, readKey }) => {
    return (
        <div>
            {reader ? (
                <div className='container py-4'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-7 col-md-6 col-sm-12'>
                            <p className='sharedInfoColor'>
                                {reader}
                            </p>
                            {readKey && (
                                <ul className='mt-5 orderList'>
                                    <li>تطوير مواقع عالية الجودة </li>
                                    <li>تلبية ميزانيتك </li>
                                    <li>دمج التقنيات الحديثة </li>
                                    <li>تسليم في الوقت المحدد </li>
                                    <li>حقق أهدافك</li>
                                </ul>
                            )}
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='service-image-container'>
                                <img
                                    src={`${urlBase}/static-uploads/${imageCover}`}
                                    alt="services"
                                    className='service-image'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton count={10} />
            )}
            <ServiceRequest />
        </div>
    )
}

export default ServiceComponent
