import React, { useEffect, useState } from 'react'
import "../../../styles/main.css"
import { VscSettingsGear } from "react-icons/vsc";
import { Link } from 'react-router-dom';
import { FaUsers } from "react-icons/fa";

const ShowProjects = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("user")) {
            const user = JSON.parse(localStorage.getItem("user"));
            setUser(user);
        }
    }, []);
    return (
        <div>
            <div>
                <div className="addService">
                    <div>
                        <p style={{ color: "white" }}>معلومات العملاء</p>
                        <div style={{ marginTop: "15px", textAlign: "center", }}>
                            <Link to={`/dashboard/${user?._id}/services-requests`}>
                                <FaUsers size="35px" color="white" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowProjects
