export const webSiteReader = "قسم البرمجة في Osbash هو القسم المسؤول عن تطوير وبرمجة الحلول التقنية والتطبيقات الرقمية لعملاء الشركة. يشتمل قسم البرمجة على فريق من المطورين والمبرمجين ذوي الخبرة والمهارة في مجموعة متنوعة من لغات البرمجة وتقنيات التطوير."

export const phoneReader = "قسم تطوير تطبيقات الهاتف في Osbash هو القسم المسؤول عن تصميم وتطوير تطبيقات الجوال لمنصات مثل iOS وAndroid و أنظمة تشغيل أخرى. يهدف هذا القسم إلى تقديم تطبيقات متميزة وفعالة لعملاء الشركة، سواء كانت تطبيقات للأعمال التجارية أو الترفيه أو أي مجال آخر."

export const marketingReader = "قسم التسويق الإلكتروني في Osbash يهدف إلى تسويق منتجات وخدمات الشركة باستخدام الوسائط الرقمية والمنصات عبر الإنترنت. يتضمن هذا القسم مجموعة من المتخصصين في التسويق الرقمي الذين يعملون على تحسين وتنفيذ استراتيجيات التسويق الإلكتروني لزيادة الوعي بالعلامة التجارية وجذب المزيد من العملاء. "
export const uxReader = "قسم تصميم تجربة المستخدم (UX) وواجهة المستخدم (UI) في شركة Osbash مسؤول عن تحسين تجربة المستخدم وتصميم واجهات مستخدم جذابة وسهلة الاستخدام لتطبيقات الويب والجوال والبرمجيات الأخرى التي تقدمها الشركة. "


export const socialReader = "قسم تحسين وسائل التواصل الاجتماعي ومحركات البحث في Osbash مسؤول عن تحسين وتعزيز وجود العميل على منصات التواصل الاجتماعي وتحسين مركزها في نتائج محركات البحث، مما يعزز الوعي بالعلامة التجارية ويجلب المزيد من الزوار والعملاء المحتملين. "

export const hoistingReader = "قسم استضافة المواقع في Osbash يقدم خدمات استضافة المواقع على الإنترنت للعملاء، مما يتيح لهم تخزين مواقعهم وتطبيقاتهم على الإنترنت وجعلها متاحة للوصول عبر الشبكة العالمية عبر الويب بشكل آمن وموثوق به. "

export const reportsReader ="قسم التحليلات والتقارير في Osbash يهدف إلى تحليل البيانات وتوليدها لفهم أداء العمليات والمشاريع الرقمية واتخاذ القرارات الاستراتيجية بناءً على البيانات المحددة."

export const maintainceSites = "إدارة المواقع وصيانتها تشير إلى عملية الإشراف والتحكم في كل جوانب الموقع الإلكتروني بما في ذلك المحتوى، والأمان، والأداء، والتوافق مع مختلف الأجهزة والمتصفحات. وتهدف هذه العملية إلى ضمان استمرارية وسلامة الموقع وتحقيق أقصى قدر من الأداء والفعالية. "

export const graphicReader = "قسم الجرافيك والموشن في Osbash يعنى بتصميم الرسومات والصور والعناصر المرئية والحركية التي تستخدم في المواقع الإلكترونية والتطبيقات والحملات التسويقية الرقمية. ويهدف هذا القسم إلى إنشاء محتوى بصري جذاب ومبتكر يسهم في تعزيز العلامة التجارية وجذب الجمهور. "